/*CONTENEDORES*/

.cardHeadModal {
    z-index: 1050;
}

.loginContainer {
    min-height: var(--loginContainerHeight);
}

.footer {
    /*espaciado para el footer*/
    height: var(--footerHeight);
}

/*alto entre header y footer*/
.usersContainer {
    height: var(--usersContainerHeight);
}

/*Contenedor de la vista de seccion*/
.sectionContainer {
    width: calc(100% - 65px);
    max-height: var(--usersContainerHeight);
    min-height: var(--usersContainerHeight);
}

/*sobreescritura de card de bootstrap*/
.card {
    border-width: 0px;
    border-radius: 0;
    min-height: inherit;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

/*RADIUS*/
.bRadius12 {
    border-radius: 12px;
}

/*contenedor del formulario de logueo*/
.formContainer {
    max-width: 510px;
    min-height: fit-content;
    height: 100%;
}

.cardHead {
    height: 36px;
}
.accordHeight {
    height: var(--cardHeadHeight);
}

.minAccordHeight {
    min-height: var(--cardHeadHeight);
}

/*ancho del responsive*/
.notificationsContainer {
    padding-bottom: 0.5rem;
    transition: width 0.5s linear;
}

.msgsContainer {
    max-height: 0px;
    overflow: hidden;
    transition: 1s;
    align-items: center;
    background-color: white;
    opacity: 0.8;
    background-size: 10px 10px;
    background-image: repeating-linear-gradient(45deg, var(--greyLightBg) 0, var(--cardHeadColor) 1px, #ececec 0, white 50%);
}

.msgSpace {
    width: 70%;
}

.displayMsgs {
    max-height: 500px !important;
    overflow-y: auto !important;
}

.newMsgIndicator {
    min-width: 15%;
    width: 80%;
    transform-origin: bottom left;
    -ms-transform: skew(-45deg, 0deg);
    -webkit-transform: skew(-45deg, 0deg);
    transform: skew(-45deg, 0deg);
}

.orderHeading {
    overflow: hidden;
}

.orderHeading * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.idPed {
    overflow: auto !important;
}

.addressUL li:not(:last-child) {
    margin-bottom: 0.8rem !important;
}

.resumenContainer {
    width: 0;
    max-height: 0;
    transition: all 0.5s linear;
    overflow-y: hidden;

    & span {
        font-weight: bold;
    }
}

.resumenDetail {
    position: fixed;
}

.showResumen {
    width: 640px !important;
    max-height: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-y: auto;
}

.cbuBox>div {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.userList {
    z-index: 10;
  position: relative;
}
.userList li:nth-child(1),
.userList li:nth-child(2) {
    text-align: end;
}