/*BOTONES*/

/*para todos los botones*/
button {
    border-radius: 4px !important;
}

.btnOrderStatus {
    width: 100px;
}

.btnCardHead, .btnDownloadOrd {
    width: 25px;
    height: 25px;
}

.btn {
    font-weight: 400;
}

.btnBlue {
    border-color: var(--peabodyBlue);
    color: var(--peabodyBlue);
}

.btnBlue:hover {
    background-color: var(--peabodyBlue);
    color: white;
}

.btnBlue:disabled {
    border-color: var(--peabodyBlue);
    color: var(--peabodyBlue);
}

.btnGreyLight {
    border-color: var(--greyText);
    color: var(--greyText);
}

.btnGreyLight:hover {
    background-color: var(--greyLightBg);
}

.btnGreyLight.show {
    background-color: #fff;
    color: var(--greyText);
}

/*Boton de crear pedido*/
#btnCreateOrder,
#btnReply,
#irPedidoBtn {
    color: white;
    z-index: 500;
    transition: 1s;
    background-size: 400% 20%;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

#irPedidoBtn {
    z-index: 0 !important;
}

/*animacion de escala*/
#btnCreateOrder:hover,
#btnReply:hover,
#irPedidoBtn:hover {
    background-position: right;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

#btnCreateOrder {
    padding: 0;
    height: var(--cardHeadHeight);
    position: sticky;
    top: calc(var(--cardHeadHeight) + var(--topbarHeight) + var(--headerHeight));
    background-image: linear-gradient(to left, var(--sapBlue), var(--peabodyBlue));

    & span {
        line-height: calc(var(--cardHeadHeight) - 8px);
        font-weight: bold;
    }
}

#irPedidoBtn {
    background-image: linear-gradient(to left, var(--sapBlue), var(--peabodyBlue));
}

.ordFilter {
    background-color: var(--tableHeadBgColorLight);
    border-color: var(--tableHeadBgColor);

}

.ordFilter:hover {
    background-color: var(--cardHeadColorLight);
    border-color: var(--cardHeadColor);
}


#btnReply {
    background-image: linear-gradient(to left, red, var(--rejectRed));
}

#btnReply:disabled {
    border: 1px solid var(--rejectRedLight) !important;
    color: var(--rejectRedLight);
}

.btnCardHead:hover,
.btn-close:hover,
.btnDownloadOrd:hover {
    transform: scale(1.5);
}

.btnCardHead,
.btn-close,
.btnDownloadOrd,
.btnSend {
    transition: 0.2s;
}

.btnModificar {
    background-color: #eff390;
}

.btnModificar,
.btnAccept,
.btnReject,
.btnSAP {
    transition: 0.5s;
    color: black;
    text-shadow: 1px 1px 2px gray;
}

/*BUTTONS HOVER*/
.btnAccept:hover {
    background-color: var(--okGreen);
}

.btnReject:hover {
    background-color: var(--rejectRed);
}

.btnSAP:hover {
    background-color: var(--sapBlue);
}
.btnSend {
    background-color: var(--cardHeadColorLight);  

    &:is(:hover, :focus) {
        background-color: var(--modificationBg) ;
    }
  }

.btnSAP:hover,
.btnAccept:hover,
.btnReject:hover,
.btnModificar:hover {
    color: white;
    font-weight: bold;
}

.btnModificar:hover {
    background-color: #d8de32;
}

#submitBtn:hover {
    background-color: white !important;
    color: var(--rejectRed);
}

#submitBtn:disabled {
    color: white;
}

.disabledDet {
    pointer-events: none;
    background-color: transparent !important;
    border-color: var(--bs-btn-disabled-border-color);
    color: var(--bs-btn-disabled-color) !important;
    opacity: var(--bs-btn-disabled-opacity)
}