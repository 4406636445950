/*ALERTS*/

/*texto de alerta*/
.errMsg {
    margin-bottom: 0px;
}
/*espacio del error*/
.alertContainer {
    height: 30px;
}
