.listaHelp {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.detailsHelp {
  margin-bottom: .5rem;
  margin-top: .5rem;
  border-radius: 5px;
  overflow: hidden;
}

.summaryHelp {
  display: block;
  padding-left: 1rem;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
}

.summaryHelp:before {
  content: '';
  border-width: .4rem;
  border-style: solid;
  border-color: transparent transparent transparent #000000;
  position: absolute;
  top: 7px;
  left: 3px;
  transform: rotate(0);
  transform-origin: .2rem 50%;
  transition: .50s transform ease;
}

.detailsHelp[open]>.summaryHelp:before {
  transform: rotate(90deg);
}
.detailsHelp[open]>summary {
  font-size: larger;

}

.detailsHelp .summaryHelp::-webkit-details-marker {
  display: none;
}

.respuesta {
  padding-left: 20px;
}

.separar {
  padding-left: 10px;
}

.ajustar {
  width: 70%;
  height: auto;
}

.imgHelp {
  object-fit: contain;
  max-height: 90dvh;
  height: auto;
  width: 100%;
  align-self: center;

}
.close-img {
  background-color: white;
  scale: 1.2;
}
.ajustar:hover {
  cursor: zoom-in;
}

#modalHelp {
  display: none;
}

#modalHelp.abierto {
  position: fixed;
  display: flex;
  z-index: 10000;
  justify-content: center;
  align-content: center;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #0000008c;
}

#cerrar {
  top: -30px;
  right: 20px;
  position: absolute;
}

.invertClr{
filter: brightness(0) invert(1);
}

.modalHelpSub {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

}

@media only screen and (max-width: 991px) {
  .ajustar {
    width: 100% !important;

  }
}

@media only screen and (max-width: 767px) {
  #cerrar {
    text-align: end;
    margin-top: -30px;
    top: auto;
    right: 0px;
  }

  .modalHelpSub {
    justify-items: center;
  }
}