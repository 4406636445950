/*VARIABLES*/
:root {

    /*MIN WIDTH*/
    --bodyMinWidth: 330px;

    /*GREY*/
    --headerBgColor: #4b4949;
    --baseText: #4A5159;
    --greyText: #616160;
    --greyLightText: #959595;
    --greyBorder: #d6d6d6;
    --greyLightBg: #f9f9f9;
    --bodyBgColor: #f4f4f4;
    /* BLUE */
    --peabodyBlue: #1d70a7;
    --sapBlue: #68b6ff;
    --sapBlueLight: #bde0ff;
    --tableHeadBgColor: #d0e4f6 !important;
    --tableHeadBgColorLight: #e9f3fc;
    /* GREEN */
    --okGreen: #37b637;
    --okGreenLight: #bcf9bc;
    --ownMsgBg: color-mix(in srgb, #bde0ff, #ecf6ff);
    --elseMsgBg: color-mix(in srgb, #acf3ac, #dbffdb);
    /* YELLOW */
    --warnYellow: #e1e416;
    --warnYellowLight: color-mix(in srgb, #f8faaf, #dadba5);
    /* RED */
    --rejectRed: #fc5359;
    --rejectRedLight: color-mix(in srgb, #ff9094, #d5b3b5);
    --modificationBg: #e27177;
    --cardHeadColor: #ffe5e6;
    --cardHeadColorLight: #fff6f6;

    /*indicador de estado en detalle*/
    --stateGreen: linear-gradient(to right, var(--bodyBgColor) 0%, var(--okGreen) 50%, var(--bodyBgColor) 100%);
    --stateYellow: linear-gradient(to right, var(--bodyBgColor) 0%, var(--warnYellow) 50%, var(--bodyBgColor) 100%);
    --stateRed: linear-gradient(to right, var(--bodyBgColor) 0%, var(--rejectRed) 50%, var(--bodyBgColor) 100%);
    --stateBlue: linear-gradient(to right, var(--bodyBgColor) 0%, var(--sapBlue) 50%, var(--bodyBgColor) 100%);
    --loadingLeft: linear-gradient(to left, var(--cardHeadColor), var(--tableHeadBgColor));

    /*color de pedidos*/
    --orderGreen: linear-gradient(180deg, var(--okGreenLight), #ebffeb 75%);
    --orderYellow: linear-gradient(180deg, var(--warnYellowLight), #feffdb);
    --orderRed: linear-gradient(180deg, var(--rejectRedLight), #ffe9ea 75%);
    --orderBlue: linear-gradient(180deg, var(--sapBlueLight), #e9f5ff 75%);
    --orderWhite: linear-gradient(180deg, #908c8c, #cdcaca 75%);

    /* gradient notificaciones */
    --notifGr: linear-gradient(to left, #b8d6e2, #edf5f9);
    --notifActive: linear-gradient(to left, #d0e5ed, var(--cardHeadColor));
    --notifHover: linear-gradient(to left, transparent, var(--cardHeadColor));

    /*height*/
    --headerHeight: 36px;
    --headerHeightResp: 26px;
    --sideBarHeight: 420px;
    --footerHeight: 32px;
    --topbarHeight: 48px;
    --cardHeadHeight: 46px;
    --cardHeadHeightSM: 24px;
    --accordionHeightSM: 32px;
    --sidebarWidth: 72px;

    /*contenedor del login*/
    --loginContainerHeight: calc(100dvh - var(--footerHeight));
    /*contenedor principal de las secciones*/
    --usersContainerHeight: calc(100vh - var(--headerHeight) - var(--footerHeight));
    --usersContainerHeightSM: calc(100vh - var(--headerHeightResp) - var(--footerHeight));
    --sectionContainerHeightMD: calc(100vh - var(--footerHeight) - var(--headerHeight) - var(--topbarHeight));
    --sectionContainerHeightSM: calc(100vh - var(--footerHeight) - var(--headerHeightResp) - var(--topbarHeight));
    

    --madeBy: 'Tks';
}