#idResumenContainer {
    overflow: hidden;
    width: 0;
    transform: translateX(150%);
    -webkit-transform: translateX(150%);
}

.slideIn {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.slideOut {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

.progress-infinite {
    height: 14px;
    width: 80px;
    border-radius: 10px;
    margin: auto;
    background-image: linear-gradient(to right, silver, #dcdcdc);
    animation: colorSlide 1s infinite
}

@keyframes colorSlide {
    from {
        background-position-x: 0;
    }
    to {
        background-position-x: 80px;
    }
}
@keyframes slide-in {
    0% {
        -webkit-transform: translateX(100%);
    }

    100% {
        -webkit-transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(100%);
    }
}

@keyframes rotateImg {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(0deg);
    }

    100%{
        transform: rotate(360deg);
    }
}