/*INPUTS*/

/*para todos los inputs*/
.inputs {
  font-size: 18px;
  border-radius: 2px;
}

.globalFilter {
  border: none;
  background-color: #fff6f6;
}

.searchTable .globalFilter:focus + img{
    transform: rotate(90deg);
}

.globalFilter:focus,
#rejectMsg:focus {
  box-shadow: none;
  border-color: var(--peabodyBlue);
}

/*cambio al seleccionar el input*/
.inputs:focus {
  border-color: var(--baseText);
  box-shadow: none;
}

.inputsNoStyle {
  border: 0;
  background: transparent;
}

.inputLabel {
  z-index: 50;
  top: -0.6rem;
  left: 1rem;
  border-radius: 10px;
  margin-left: 0 !important;
}

.inputLabelShort {
  left: 0.5rem !important;
}

.inputLabelCenter {
  left: 30% !important;
}

.labelEnd {
  right: 8px !important;
  left: auto;
}

.replyMsg {
  border: none;
}

.detailsMsg>summary {
  list-style-type: none;
}

#Direccion_Entrega_New {
  padding-left: 40px;
}

/******** new slider switch *************/
.switch-container {
  position: relative;
  width: 54px;
  height: 20px;
}

.checkText-container {
  position: relative;
  width: 54px;
  height: 26px;
  border: 1px solid #ced4da;
  border-left: 3px solid var(--peabodyBlue);
  justify-content: center;
  min-width: 63px;
  width: fit-content;
  box-shadow:
    2px 2px 3px rgb(219, 219, 219),
    2px -2px 3px rgb(219, 219, 219);
}

.checkText-container:hover {
  border-top: 1px solid var(--peabodyBlue);
  border-right: 1px solid var(--peabodyBlue);
  border-bottom: 1px solid var(--peabodyBlue);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 0%;
  transition: 0.7s;
}

.checked {
  background-color: var(--peabodyBlue);
  width: 100%;
}

.checkText {
  z-index: 10;
}

.maxHeigth58 {
  max-height: 58.5px;
}
