/*todas las celdas*/
.modTable * {
    vertical-align: middle;
    border-style: none;
    text-align: center;
}

/*color de las filas (impares)*/
.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
    background-color: #ebe5e52f;
}

/*color de fila al pasar mouse */
.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    background-color: var(--cardHeadColorLight);
}

/*filas*/
.modTable tr {
    height: 48px;
}

/*celdas*/
.modTable td {
    white-space: nowrap;
    overflow: hidden;
    max-width: fit-content;
    max-width: 450px;
    text-overflow: ellipsis;
    color: var(--baseText) !important;
}

.modTable th {
    min-width: 130px;
    max-width: 200px;
    color: var(--greyText) !important;
}

/*acordeon de columnas*/
.accordionBtn:focus {
    box-shadow: none;
    border: none;
}

.accordionBtn::after {
    margin-left: 0 !important;
}

.accordionBtn {
    justify-content: flex-end;
}

.accordionContainer {
    max-width: 100vw;

}

/**********TABLA DE PRODUCTO***************/
.tableProducts td,
th {
    font-size: 14px;
    color: var(--baseText);
}

.tableProducts thead th {
    color: black;
}

/*marca las lineas excepto la columna de la imagen del pallet*/
.tableProducts tr:hover td:not(#noHover) {
    background: rgb(228, 225, 225);
}

.stickyHead {
    position: sticky;
    top: 0;
    z-index: 200;
}

/* tabla de montos*/
.montosTable {
    border-spacing: 10px;
    border-collapse: separate;
}

.columnsDet[open] {
    background-image: linear-gradient(90deg, var(--cardHeadColor) 25%, #ffffff 75%);

    & .downBtn {
        transform: rotate(-90deg);
    }
}

.downBtn {
    width: 10px;
    height: 10px;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
    rotate: -45deg;
    transition: 0.5s;
}