/*COLORES*/

/*color del body*/
body {
  background-color: var(--bodyBgColor);
}

.clrBgHeader {
  background-color: var(--headerBgColor);
}

/*color del boton inicio*/
.clrBgPrimary {
  background-color: var(--rejectRed) !important;
}

.clrBgPeabBlue {
  background-color: var(--peabodyBlue);
}

.clrBgGreyMd {
  background-color: #ececec;
}

.clrBgAccept {
  background-color: var(--okGreenLight);
}

.clrBgDecline {
  background-color: var(--rejectRedLight);
}

.clrBgForward {
  background-color: var(--sapBlueLight);
}

.clrBgTableHead {
  background-color: var(--tableHeadBgColor);
}
.clrBgTableHeadLight {
  background-color:  var(--tableHeadBgColorLight);
 }
.modification {
  background-color: var(--cardHeadColor) !important;
  border: 1px solid rgba(255, 0, 0, 0.267);
  width: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.clrBgWhite {
  background-color: white;
}

.clrBgPink {
  background-color: var(--cardHeadColor);
}

.clrBgPinkLight {
  background-color: var(--cardHeadColorLight);
}

.clrBgLightBlue {
  background-color: var(--sapBlueLight);
}

.clrBgLightGray {
  background-color: var(--greyLightBg);
}

.clrBgLightGreen {
  background-color: var(--okGreenLight);
}

.clrBgLightGreenGr {
  background-image: linear-gradient(to left, var(--okGreenLight) 20%, transparent);
}

.clrBgOwnMsg {
  background-color: var(--ownMsgBg);

}

.clrBgElseMsg {
  background-color: var(--elseMsgBg);
}

.clrBgCliDetA {
  background-image: linear-gradient(180deg, #fffafa 25%, #eff4ff 75%);
}
.clrBgCliDetB {
  background-image: linear-gradient(180deg, #fffafa 25%, #ffefef 75%);
}
/*ESTADO (orderdetail)*/
.estadoRed {
  background-image: var(--stateRed);
}

.estadoGreen {
  background-image: var(--stateGreen);
}
.estadoGrey{
  background-image: var(--orderWhite);
}
.estadoYellow {
  background-image: var(--stateYellow);
}

.estadoYellowReverse {
  background-image: var(--stateYellowReverse);
}

.estadoCargado {
  background-image: var(--stateBlue);
}

/*GRADIENTS izq a der*/
.clrGrRechazado,
.clrGrModificado {
  background-image: var(--orderRed);
}

.clrGrRechazado:before {
  background-image: linear-gradient(180deg, transparent, var(--rejectRedLight));
}

.clrGrAutorizado {
  background-image: var(--orderGreen);
}

.clrGrAutorizado:before {
  background-image: linear-gradient(180deg, transparent, var(--okGreenLight));
}

.clrGrPendiente {
  background-image: var(--orderYellow);
}

.clrGrPendiente:before {
  background-image: linear-gradient(180deg, transparent, var(--warnYellowLight));
}

.clrGrCargado {
  background-image: var(--orderBlue);
}

.clrGrCargado:before {
  background-image: linear-gradient(180deg, transparent, var(--sapBlueLight));
}

.clrGrPreliminar {
  background-image: var(--orderWhite);
}

.orderHeading:before {
  content: '';
  display: block;
  position: absolute;
  height: 0%;
  width: 100%;
  bottom: 0;
  transition: height 0.5s ease-out;
}

.orderHeading:hover:before {
  height: 100%;
}

.notifHeading:before {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 0%;
  left: 0;
  transition: width 0.7s ease-out;
  background-image: var(--notifHover);
}

.notifHeading:hover:before {
  width: 100%;
}

.clrGrMensajes {
  background-image: var(--notifGr);
}

.clrGrMensajesActive {
  background-image: var(--notifActive);
}

.clrGrToBottomGrey {
  background-color: var(--greyLightBg);
  background-image: linear-gradient(var(--greyLightBg), transparent);
}

/* COLORS TEXT */
/*color del texto general*/
body {
  color: var(--baseText);
}

.textGreen {
  color: var(--okGreen);
}

.textRed {
  color: var(--rejectRed);
}

.textBlack {
  color: black;
}

.textWhite {
  color: white;
}

/*color de texto gris full (general)*/
.textColorGrey {
  color: var(--greyText);
}

/*color de texto gris azulado (sidebar)*/
.textColorGreyM {
  color: var(--baseText);
}

/* gris claro labels ord form */
.textColorGreyL {
  color: var(--greyLightText);
}

/*BORDERS*/
.clrBorderRed {
  border-color: var(--rejectRed) !important;
}

.borderS {
  border-left: 1px solid var(--greyBorder);
}

.borderB {
  border-bottom: 1px solid var(--greyBorder);
}

.invert {
  filter: invert();
}