/*HEIGHT*/
.height100vh {
    height: 100vh;
}

.heightFitCont {
    height: fit-content;
}

.height64px {
    height: 64px;
}

.h64px {
    height: 46px;
}

.hInherit {
    height: inherit;
}

.height32px {
    height: 32px;
}

/*MAX HEIGHT*/
.maxH100 {
    max-height: 100%;
}

.maxHInherit {
    max-height: inherit;
}

/*MIN HEIGHT*/
.minh32px {
    min-height: 32px;
}

.minh46px {
    min-height: 46px;
}

.minH24px {
    min-height: 24px;
}

.minHFitCont {
    min-height: fit-content;
}

/*WIDTH*/
.widthFitCont {
    width: fit-content;
}

.widthAuto {
    width: auto !important;
}

.widthBancos {
    width: 140px;
}

.widthCuentaCorr {
    width: 210px
}

.wMaxContent {
    width: max-content !important;
}
.w90 {
    width: 90%;
}

/*MAX WIDTH*/
.maxWFitCont {
    max-width: fit-content;
}

.maxW460 {
    max-width: 460px;
}

/*barra de busqueda*/
.searchTable {
    max-width: 70%;
}

.globalFilter {
    padding-left: 40px;
}

/*MIN WIDTH*/
.minWFitCont {
    min-width: fit-content;
}

.minW79px {
    min-width: 79px;
}

.minW290px {
    min-width: 290px;
}

.minWidth480 {
    min-width: 480px;
}

.minW45 {
    min-width: 45%;
}

.minWMaxContent {
    min-width: max-content;
}

.hw18px {
    height: 18px;
    width: 18px;
}

.gap10 {
    gap: 10%;
}

/*index 100 para el encabezado de las tarjetas*/
.index2000 {
    z-index: 300;
}

.index100 {
    z-index: 100;
}

/*DISPLAY*/
.displayNone {
    display: none !important;
}

.displayInlineBlock {
    display: inline-block;
}

/*OVERFLOW*/
.overflowXscroll {
    overflow-x: scroll;
}