/*Bootstrap breakpoints
mobile phones (≥ 576px);
tablets (≥768px);
laptops (≥992px);
desktops (≥1200px)*/

@media only screen and (max-width: 1199px) {
    .msgSpace {
        width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .pb0LG {
        padding-bottom: 0 !important;
    }

    .px0LG {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    /*clases para sobreescribir la alineacion*/
    .textLeftLG {
        text-align: left !important;
    }

    .textCenterLG {
        text-align: center !important;
    }

    /*esconde el carrusel del login*/
    .displayNoneLG {
        display: none;
    }

    .displayLG {
        display: block !important;
    }

    .sidebarRespContainer {
        position: sticky;
        z-index: 100;
    }

    .menuCategory {
        width: 50px;
    }

    .iconBox {
        width: 100% !important;
    }

    /*esconde el texto de las categorias*/
    .nav-link span {
        display: none !important;
    }

    /*cambia el estilo del sidebar a topbar*/
    .sidebarUser {
        justify-content: center;
        flex-direction: row !important;
        flex-wrap: nowrap;
        width: fit-content;
        margin: auto;
        height: var(--topbarHeight) !important;
    }

    /*cambia el ancho del sidebar*/
    .sidebarContainer {
        width: 100% !important;
        margin-bottom: 0px;
        margin-top: 0px;
        overflow-x: inherit !important;
        min-height: auto !important;
    }

    #signout {
        border-top: none;
        margin: 0px;
    }

    /*estilo de bategoria activa*/
    .activeCate {
        border-left: none !important;
        border-bottom: 5px solid var(--peabodyBlue);
    }

    #menu li {
        padding-top: 0px !important;
        padding-bottom: 0px;
        height: inherit !important;
        margin-bottom: 0 !important;

        & a {
            max-height: inherit;
        }
    }

    /*selector ultimo item del menu*/
    #menu li:last-child {
        margin-top: 0 !important;
        border-top: 0px !important;
    }

    /*cambia el estilo al pasar el mouse*/
    ul#menu li:hover {
        background-color: #f0f6ff;
        border-left: none;
    }

    .card {
        margin: 0;
    }

    .sectionContainer {
        padding: 0 !important;
        width: 100%;
        max-height: var(--sectionContainerHeightMD);
        min-height: var(--sectionContainerHeightMD);
    }

    .usersContainer {
        flex-direction: column;
    }

    /*tamaño minimo del modal de detalles*/
    .modalProd {
        min-width: 80vw
    }

    .montosTable {
        margin: auto;
        width: 45%;
    }

    .width100LG {
        width: 100% !important;
    }

    .redDot {
        left: 2.2rem !important;
    }

    .cbuBox>div {
        padding-bottom: 0.2rem;
        margin-bottom: 0.2rem;
    }
}


/*SMARTPHONE LARGE*/
@media only screen and (max-width: 767px) {

    .px15MD {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .datosUser p {
        height: 100%;
        padding-left: 17vw;
    }

    .width100MD {
        width: 100%;
    }

    .datosUser {
        padding-top: 4.5vw;
        margin: auto;
    }

    /*barra de busqueda en tablas*/
    .searchTable {
        max-width: 90%;
    }

    /*amplia el contenedor del login*/
    .loginContainer {
        width: 100%;
        padding: 0 !important;
        margin: 0 !important;
    }

    .formContainer {
        max-width: none;
        margin: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .flexColumnMD {
        flex-direction: column !important;
    }

    .flexRowMD {
        flex-direction: row !important;
    }

    #crOrdDetail {
        padding: 0;
        max-width: none;
    }

    .cardHead {
        padding-left: 12px !important;
        padding-right: 8px !important;
    }

    .py0MD {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .w70MD {
        width: 70%;
    }

    .textCenterMD {
        text-align: center;
    }

    .textRightMD {
        text-align: end !important;
    }

    .textLeftMD {
        text-align: start !important;
    }

    .orderLastMD {
        order: 6 !important;
    }

    .borderTopMD {
        border-top: 1px var(--greyBorder) solid;
    }

    .borderEndNoneMD {
        border-right: none !important;
    }

    .displayNoneMD,
    .resumenContainer {
        display: none !important;
    }

    .displayMD {
        display: block !important;
    }

    .commentsDetSM {
        border-left: 0 !important;
        margin-top: 1rem;
        align-items: end;

        :first-child {
            flex-direction: row-reverse;

            :last-child {
                margin-left: 0;
                margin-right: 1rem;
            }
        }
    }
}

/*TODO corregir altura general*/
/*SMARTPHONE MEDIUM*/
@media only screen and (max-width: 575px) {

    .displayNoneSM {
        display: none;
    }

    .flexColumnSM {
        flex-direction: column;
    }

    .flexRowSM {
        flex-direction: row !important;
    }

    .arrow span {
        height: 8px !important;
    }

    .usersContainer {
        height: var(--usersContainerHeightSM) !important;
    }

    .sectionContainer {
        max-height: var(--sectionContainerHeightSM);
        min-height: var(--sectionContainerHeightSM);
    }

    .modalBase {
        top: var(--headerHeightResp) !important;
        max-height: calc(100vh - var(--headerHeightResp)) !important;
    }

    .searchTable {
        max-width: 70% !important;
        margin-left: auto !important;
        font-size: small;
        height: 1.5rem;
    }

    .globalFilter {
        border-radius: .375rem 0 0 .375rem !important;
        padding-left: 30px !important;
    }

    .cardHead {
        height: auto;
        min-height: 24px;
        padding-left: 0 !important;
        padding-right: 0 !important;

        & span {

            font-size: initial !important;
        }

        & img {
            height: 25px !important;
        }
    }

    .ps0SM {
        padding-left: 0 !important;
    }

    .px05SM {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .px0SM {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .pt02SM {
        padding-top: 0.25rem;
    }

    .m0SM {
        margin: 0 !important;
    }

    .msAutoSM {
        margin-left: auto !important;
    }

    /*equivalente a mt-1*/
    .mt2SM {
        margin-top: calc(1rem * 0.25);
    }

    .mx05SM {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }

    .me0SM {
        margin-right: 0 !important;
    }

    .w50SM {
        width: 50% !important;
    }

    .w75SM {
        width: 75% !important;
    }

    .datosUser p {
        padding-left: 4vw;
    }

    .justifyContAroundSM {
        justify-content: space-around !important;
    }

    .gap0SM {
        gap: 0 !important;
    }

    .p05SM {
        padding: 0.5rem !important;
    }

    .pt0SM {
        padding-top: 0 !important;
    }

    .px05SM {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .px15SM {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .px8SM {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .pt1SM {
        padding-top: 1rem;
    }

    .pt05SM {
        padding-top: 0.5rem;
    }

    .pb35px {
        padding-bottom: calc(var(--cardHeadHeight) + 4px);
    }

    .py4pxSM {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    .py0SM {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .headBar h3 {
        margin-left: 1rem !important;
        font-size: smaller !important;
    }

    .headBar {
        height: var(--headerHeightResp) !important;
    }

    .menuIco {
        width: 17px;
    }

    .accordionBtn {
        height: 2rem;
    }

    .textLeftSM {
        text-align: left !important;
    }

    .loaderIndi {
        width: 19px !important;
        height: 19px !important;
    }

    /*tablas*/
    .modTable * {
        font-size: small;
    }

    .modTable tr {
        height: 40px;
    }

    .ordFilterSM {
        z-index: 400;
        height: var(--cardHeadHeightSM);
    }

    .filterContainer {
        position: absolute;
        top: 0;
        gap: 0.25rem !important;
    }

    .posRelativeSM {
        position: relative;
    }

    .btnCreateOrderSM {
        position: fixed !important;
        bottom: 35px;
        top: auto !important;
        left: 0;
    }

    .h0px {
        min-height: 0px !important;
    }

    .btPink {
        border-top: solid 4px var(--cardHeadColorLight);
    }

    .accordHeight {
        height: var(--accordionHeightSM) !important;
    }

    .minAccordHeight {
        min-height: var(--accordionHeightSM) !important;
        ;
    }

    .fontSizeSmallSM {
        font-size: small !important;
    }

    .editUserBtn {
        position: fixed;
        top: 110px;
        right: 10px;
    }

    .chatAvatar {
        width: 40px;
    }

    .fs1rSM {
        font-size: 1rem;
    }

    .fs14SM {
        font-size: 14px;
    }

    .userImg {
        height: 160px;
    }

    .accDirPedido {
        max-width: 144px;
        min-width: 144px;
    }

    .notifOrderDets {
        padding-right: 8px !important;
        padding-left: 0px !important;
    }

    .modalContent .row {
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
    }

    .modalInputs {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }

    .w-100SM {
        width: 100% !important;
    }

    .warnIco {
        height: 28px;
    }
}


/*medida especial para la barra de menu 
    cambiar si se agregan secciones*/
@media only screen and (max-width: 443px) {

    .flexColumnXs {
        flex-direction: column;
    }

    .textLeftXs {
        text-align: left !important;
    }

    .mt1XS {
        margin-top: 1rem;
    }

    .mt0XS {
        margin-top: 0 !important;
    }

    .textRightXs {
        text-align: end !important;
    }

    .dFlexXS {
        display: flex;
    }

    .flexSpaceBetweenXS {
        justify-content: space-between;
    }

    /* .stateIndicator{
        width: 100% !important;
    } */

}


/* HEIGHT */
@media only screen and (max-height: 630px) {
    #menu li {
        margin-bottom: 2px !important;
    }
}

@media only screen and (max-height: 553px) {
    #menu li {
        height: 48px !important;
    }
}