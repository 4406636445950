/*TEXTOS*/
.longText {
    word-break: break-all;
}

.fontSize11 {
    font-size: 11px;
}

.fontSize13 {
    font-size: 13px;
}

.fontSize14 {
    font-size: 14px;
}

.fontSize15 {
    font-size: 15px;
}

.fontSize17 {
    font-size: 17px;
}

.fontSize18 {
    font-size: 18px;
}

.fontSize24 {
    font-size: 24px;
}

.fontSize17rem {
    font-size: 1.7rem;
}
.fontSizeH1L {
  font-size: calc(1.7rem + 1.5vw);
}
.fontSizeH3L {
    font-size: calc(1.3rem + .9vw);
}

.textSmall {
    font-size: smaller;
}

.textEllipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.spacing-1 {
    letter-spacing: -1px;
}

.WSpreWrap {
    white-space: pre-wrap;
}

.WSpre {
    white-space: pre;
}

.cardHead span {
    font-weight: bold;
}

.ordStateHeader {
    color: white;
    font-size: 90px;
    right: 0;
    width: fit-content;
    text-transform: uppercase;
    font-weight: bolder;
    word-wrap: normal;
    overflow: hidden;
}

.ordShRechazado {
    text-shadow: 1px -1px #ffe3e3 !important;
}

.ordShPendiente {
    text-shadow: 1px -1px #fffecd !important;
}

.ordShPreliminar {
    text-shadow: 1px -1px #d8d8d8 !important;
}

.ordShCargado {
    text-shadow: 1px -1px #98aec2 !important;
}

.textShadow0 {
    text-shadow: none !important;
}

/* .orderItem *:not(.ordState) { */
.orderItem *:not(.newMsgIndicator) {
    z-index: 10;
    text-shadow: 1px 1px 9px #c9f0ff;
}

/*/// transformaciones en texto ///*/
.hoverSwipe {
    position: relative;
    min-height: fit-content;
}

.xxBigger {
    transition: font-size 0.4s;
}

.bigger {
    transition: font-size 0.4s;
}

.hoverSwipe:hover .bigger {
    font-size: larger;
}

.hoverSwipe:hover .xxBigger {
    font-size: xx-large;
}

.dppPer,
.dppVal {
    position: absolute;
    right: 0;
}

.dppVal {
    opacity: 0;
}

.hoverSwipe:hover .dppVal {
    opacity: 1;
}

.hoverSwipe:hover .dppPer {
    opacity: 0;
}

.dppVal,
.dppPer {
    transition: opacity 0.4s linear;
}

#valuesBox * {
    text-shadow: 1px 1px var(--greyLightBg);
}

.ordDetailInfo {
    row-gap: 8px;
}

.ordDetailInfo label,
.ordDetailInfo span {
    display: block;
    text-align: start;
}

.ordDetailRow label,
.ordDetailRow span {
    text-align: center;
}

.labelTop {
    font-size: small;
    top:-16px;
    right: 0;
}
.msgSubject {
    margin-bottom: 1rem;
}
.underlineGreen {
    border-bottom: 1px solid var(--okGreen) !important;
}
.underlineBlue {
    border-bottom: 1px solid var(--sapBlue) !important;
}
.underlineGrey {
    border-bottom: 1px solid #d5d5d5 !important;
}
.textShadowLight {
    text-shadow: 1px 1px 4px white;
} 