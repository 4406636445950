/*contenedor del sidebar*/
.sidebarContainer {
    width:  var(--sidebarWidth);
    overflow-x: hidden;
}
.catLink{
    display: flex;
}


.sidebarUser {
    width: inherit;
}

/*iconos del sidebar*/
 .menuIco { 
height: 1rem;
     scale: 1.2;
    object-fit: contain; 
} 

.iconBox {
    display: flex;
     justify-content: center; 
     align-items: center; 
    height: 100%;
    width: auto;
    aspect-ratio: 1/1;
    object-fit: contain;
}

/*seleccionables del sidebar*/
#menu li {
    height: 55px;
    display: inline-block;
    list-style-type: none;
    vertical-align: middle !important;
    margin-bottom: 15px;
    
    & a {
        overflow: hidden;
        max-width: var(--sidebarWidth);
        display: flex;
        flex-direction: column;
        height: 100%;

        & span {
            font-size: small;
            padding-bottom: 8px;
        }
    }
}

.menuCategory {
    height: 52px;
    min-width: max-content;
}

/*HOVER*/
#menu li:hover {
    background-color: #f0f6ff;

    & .menuIco {
        height: 1.1rem;
    }
}

/*categoria activa*/
.activeCate {
    background-color: var(--tableHeadBgColorLight);
    border-left: 5px solid var(--peabodyBlue);
    font-weight: bold;
    font-size: large;
}

/* ante ultimo boton (ajustes) */
#menu li:nth-child(6) {
    margin-top: auto;

    & img {
        filter: contrast(10%);
    }
}

#menu li:last-child {
    margin-bottom: 0.5rem !important;

    & span {
        display: none;
    }
}