/*importar aqui nuevas hojas de estilo*/
@import "_variables.css";
@import "_buttons.css";
@import "_containers.css";
@import "_images.css";
@import "_inputs.css";
@import "_responsive.css";
@import "_text.css";
@import "_alerts.css";
@import "_tables.css";
@import "_colors.css";
@import "_sizing.css";
@import "_sidebar.css";
@import "_modales.css";
@import "_header.css";
@import "_animations.css";
@import "_manual.css";
@import "_adminpanel.css";
@import "_grid.css";

/*importacion de fuentes*/
/*Helvetica Neue*/
@font-face {
  font-family: "HelveticaNeueLt";
  src: local("HelveticaNeue"),
    url("../assets/fonts/HelveticaNeue/HelveticaNeueLTStd-Lt _1_.otf") format("opentype");
}

/*Montserrat*/
@font-face {
  font-family: "MontserratRegular";
  src: local("Montserrat"),
    url("../assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

/*generales*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "MontserratRegular", 'HelveticaNeueLt', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

html {
  min-width: var(--bodyMinWidth);
}

.row {
  margin: 0;
}

.cursorDefault {
  cursor: default;
}

.cursorPointer {
  cursor: pointer;
}

.pointerEventNone {
  pointer-events: none;
}

.noSelect {
  user-select: none;
}

.transition {
  transition: 0.5s;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-left: -10px
  }

  100% {
    opacity: 1;
    margin-left: 0px
  }
}