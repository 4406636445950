.loaderIndi {
    border: 6px solid transparent;
    border-top: 6px solid var(--tableHeadBgColor);
    border-bottom: 6px solid var(--cardHeadColor);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: inline-block;
    animation: spin 2s linear infinite;
    margin-right: 2.2rem;
    margin-top: auto;
    margin-bottom: auto;
}
.loaderIndi2 {
    border: 12px solid transparent;
    border-top: 15px solid var(--tableHeadBgColor);
    border-bottom: 12px solid var(--cardHeadColor);
    border-radius: 50%;
    width: 150px;
    height: 150px;
    display: center;
    align-items: center;
    margin: 2rem    ;
    animation: spin 2s linear infinite;
   
}

  

.loaderIndiBig {
    width: 80px;
    height: 80px;
    border: 12px solid transparent;
    border-top: 12px solid var(--tableHeadBgColor);
    border-bottom: 12px solid var(--cardHeadColor);

}

.sapIndiA {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 20px;
    transform: rotate(45deg);
}
.sapIndiASizeOverride {
    width: 128px !important;
    height: 128px !important;
}
.borderBlue {
    border: 5px solid var(--peabodyBlue);
}

.sapIndiB {
    width: 60px;
    height: 60px;
    right: -10px;
}

.sapIndiC {
    z-index: 10;
    right: 10px;
    color: #ffffffcf; 
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*HEADER*/
.headBar {
    color: white;
    height: var(--headerHeight);
    min-width: 276px;
}

.headBar h3 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.headLine {
    position: absolute;
    height: 4px;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg,
            transparent 50%,
            var(--peabodyBlue),
            var(--peabodyBlue));
}

.headLine {
    animation: animateBottom 2.5s ease-in-out infinite;
}

.load-wrapp {
    float: left;
    width: 150px;
    height: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: white;
    padding: 2px;
  }
  
  .load-wrapp p {
    padding: 0 0 20px;
  }
 
  
  .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    font-size: 25px;
  }

.letter {
    float: left;
    
    color: #777;
  }
  
  .bar {
    float: left;
    width: 15px;
    height: 6px;
    border-radius: 2px;

  }
 
  .load-3 .line:nth-last-child(1) {
    animation: loadingC 0.6s 0.1s linear infinite;
  }
  .load-3 .line:nth-last-child(2) {
    animation: loadingC 0.6s 0.2s linear infinite;
  }
  .load-3 .line:nth-last-child(3) {
    animation: loadingC 0.6s 0.3s linear infinite;
  }
  .load-3 .line:nth-last-child(4) {
    animation: loadingC 0.6s 0.4s linear infinite;
  }
  .load-3 .line:nth-last-child(5) {
    animation: loadingC 0.6s 0.5s linear infinite;
  }
  .load-3 .line:nth-last-child(6) {
    animation: loadingC 0.6s 0.6s linear infinite;
  }
  .load-3 .line:nth-last-child(7) {
    animation: loadingC 0.6s 0.7s linear infinite;
  }
  .load-3 .line:nth-last-child(8) {
    animation: loadingC 0.6s 0.8s linear infinite;
  }
 
  
  @keyframes loadingC {
    0% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, 15px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  
@keyframes animateBottom {
    0% {
        left: 0px;
        width: 0%;
    }

    50% {
        width: 100%;
    }

    80% {
        right: 0px;
        width: 0%;
    }
}



