/*IMAGENES E ICONOS*/

/*ojo del password input*/
.eyeIcon {
    height: 35%;
    right: 3%;
    align-self: center;
    position: absolute;
    background-color: transparent;
    cursor: pointer;
}

/*imagen en pantalla de login*/
.imgLogin {
    display: block;
    width: 90%;
    margin: auto;
    max-width: 700px;
    max-height: 700px;
}

/*icono 20x20*/
.icon20px {
    height: 20px;
    width: auto;
}

.tickIcon {
    height: 10px;
    width: 10px;
    bottom: 5px;
    right: 10px;
}

.tickIconB {
    right: 15px;
}

/*imagen de los productos*/
.imgProducto {
    height: 9rem;
}

.imgPallet {
    height: 200px;
}

/*avatar del chat*/
.chatAvatar {
    width: 50px;
    height: auto;
    aspect-ratio: 1/1;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

/*notification*/
.redDot {
    position: absolute;
    left: 3rem;
    top: 0.25rem;
    height: 8px;
    width: 8px;
    color: var(--rejectRed);
    background-color: var(--rejectRed);
    border-radius: 50%;
}

.triangleLeft {
    width: 0;
    height: 0;
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 60px solid #b8d4e0;
    scale: -44%;
    align-self: center;
    position: absolute;
    right: 0;
}

.accDirPedido {
    max-width: 166px;
    min-width: 166px;
}

.icon40px {
    height: 40px;
    width: auto;
}

.icon25px {
    height: 25px;
    width: auto;
}

.icon20px {
    height: 20px;
    width: auto;
}

.icon30px {
    height: 30px;
    width: auto;
}

.icon32px {
    height: 32px;
    width: auto;
}

.icon38px {
    height: 38px;
    width: auto;
}

.contrast60 {
    filter: contrast(60%);
}

.warnIco {
    height: 34px;
    width: auto;
}
.userImg {
    animation: rotateImg 7s infinite;
}

