.detailsUsers summary > * {
  display: inline ;
}

.detailsUsers[open] .detailsContent {
background-color: var(--tableHeadBgColorLight);
}

.detailsUsers[open] summary {
    background: var(--notifHover);
}

.permitInputError {
  border: 1px solid red;
}
.permitInput {
  background-color: transparent;
 /* appearance: none;*/
}
.addUserForm input {
  padding: 0.25rem;
}

.summaryHelp {
  padding-bottom:0.5rem;
transition: 0.5s;
}
.checkBtn, .checkBtn:focus {
  border: 1px solid var(--peabodyBlue);
  color: var(--peabodyBlue);
  font-weight: bold;
}

.checkedBtn {
  background-color: var(--peabodyBlue) !important;
  color: white !important;
  border: 1px solid var(--peabodyBlue);
}
.addUserIco {
  filter: hue-rotate(30deg);
}