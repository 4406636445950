.parentGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .div1 { grid-area: 1 / 1 / 2 / 2; }
    .div2 { grid-area: 1 / 2 / 2 / 3; }
    .div3 { grid-area: 2 / 1 / 3 / 3; }
    .div4 { grid-area: 1 / 3 / 3 / 4; }
    .div5 { grid-area: 3 / 1 / 4 / 2; }
    .div6 { grid-area: 3 / 2 / 4 / 3; }
    .div7 { grid-area: 3 / 3 / 4 / 4; }