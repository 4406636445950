.modalBase {
  position: fixed;
  top: var(--headerHeight);
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
  max-height: calc(100vh - var(--headerHeight));
  overflow: auto;
}

.width38Modal {
  width: 38rem;
}

.width50Modal {
  width: 50rem;
  max-width: 50rem;
}

/*centrado y a la derecha (boton X)*/
.centerEnd {
  margin-top: auto;
  margin-bottom: auto;
}

#ordFormRef label,
.modalInputs label {
  background-image: linear-gradient(var(--greyLightBg), var(--greyLightBg), transparent);
}

.modalBackdrop {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1150;

}

.modalBackdrop[open] {
  display: flex;
}

.modalContent {
  align-self: center;
  width: clamp(480px, 70dvw, 600px);
  height: clamp(480px, fit-content, 100dvh);
}

.exportMod {
  overflow: scroll;
}

.exportOrderContent {
  overflow: auto;
  margin: auto;
  max-width: 21cm;
  background-color: white;
  position: relative;
}

.exportMinW {
  min-width: 21cm;
}

.borderedTable td,
th {
  text-align: center;
  padding: 2px;
  border: 1px solid rgb(220, 220, 220);
}

.borderedTable {
  width: 100%;
  font-size: small;
}

.exportOrdLabel {
  position: absolute;
  top: -14px;
}

.exportData {
  margin-bottom: 10px;
  border-left: #bdbde6 solid 1px;
}

.exportDetTable {
  width: 85%;
  margin: auto;

  & td {
    padding-top: 0.1rem;
    padding-left: 0.25rem;
  }
}

.exportDetTable tbody tr td:nth-child(1),
.exportDetTable tbody tr td:nth-child(3) {
  width: 20%;
}

.exportDetTable tbody tr td:nth-child(2),
.exportDetTable tbody tr td:nth-child(4) {
  width: 30%;
}

.exportOrdLabel+span {
  padding-left: 0.4em;
  line-height: normal;
}

.expDataBg {
  background-color: #f3f3f3;
}